<template>
    
    <div class="grid lg:grid-cols-2">
        <aside class="py-[30px] lg:px-[60px] px-[20px] h-[100vh] overflow-auto no-scrollbar">
            <header class="">
                <router-link to="/">
                    <div class="flex items-center gap-[6px] mb-[60px]">
                        <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
                        <div class="font-itim text-heading-5">Foodcart</div>
                    </div>
                </router-link>

                <div class="font-medium text-heading-4">Registration</div>
                <div class="mt-[24px] lg:w-[528px]">
                    <div class="text-caption text-grey-200">{{ currentStep === 1 ? '1' : '2' }} of 2</div>
                    <div class="h-[4px] w-full rounded-[8px] bg-grey-50 mt-[14px]">
                        <div class="h-[4px] bg-primary-300 rounded-[8px]" :class="currentStep === 1 ? 'w-[50%]' : 'w-[100%]'"></div>
                    </div>
                </div>
            </header>

            <main class="mt-[48px]">
                <form @submit.prevent="handleRegistrationSubmit($event)">
                    <div class="grid gap-[16px]" v-if="currentStep === 1">
                        <InputField
                            type="text"
                            label="Store Name"
                            name="name"
                            :value="registrationFormHandler.values.name"
                            :errors="registrationFormHandler.errors.name"
                            @update:blur:value="registrationFormHandler.handleBlur"
                            @update:value="registrationFormHandler.handleChange"
                        />
                        <DropdownField
                            :optionsData="[
                                {name: 'Dine-In', value: 'Dine-In'},
                                {name: 'Take-Out', value: 'Take-Out'},
                                {name: 'Phone-Orders', value: 'Phone-Orders'},
                                {name: 'Reservation', value: 'Reservation'},
                                {name: 'Fine Dinning', value: 'fine-dinning'},
                                {name: 'Casual Dinning', value: 'casual-dinning'},
                                {name: 'Fast Casual', value: 'fast-casual'},
                                {name: 'Bar / Night Club & Restaurant', value: 'bar-night-club-restaurant'},
                                {name: 'Cafe & Bakery', value: 'cafe-bakery'},
                                {name: 'Enterprise / Franchise', value: 'enterprise-franchise'},
                                {name: 'Food Truck', value: 'food-truck'},
                                {name: 'Pizza ', value: 'pizza'},
                                {name: 'Ghost Kitchen', value: 'ghost-kitchen'},
                            ]"
                            label="Store Category"
                            name="serviceOptions"
                            :value="registrationFormHandler.values.serviceOptions"
                            :errors="registrationFormHandler.errors.serviceOptions"
                            @change="registrationFormHandler.handleChange"
                        />
                        <div class="grid grid-cols-2 gap-[12px]">
                            <InputField
                                type="text"
                                label="First Name"
                                name="firstName"
                                :value="registrationFormHandler.values.firstName"
                                :errors="registrationFormHandler.errors.firstName"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />
                            <InputField
                                type="text"
                                label="Last Name"
                                name="lastName"
                                :value="registrationFormHandler.values.lastName"
                                :errors="registrationFormHandler.errors.lastName"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />
                        </div>
                        <InputField
                            type="text"
                            label="Email"
                            name="email"
                            :value="registrationFormHandler.values.email"
                            :errors="registrationFormHandler.errors.email"
                            @update:blur:value="registrationFormHandler.handleBlur"
                            @update:value="registrationFormHandler.handleChange"
                        />
                        <InputField
                            type="text"
                            label="Phone Number"
                            name="phone"
                            :value="registrationFormHandler.values.phone"
                            :errors="registrationFormHandler.errors.phone"
                            @update:blur:value="registrationFormHandler.handleBlur"
                            @update:value="registrationFormHandler.handleChange"
                        />
                        <DropdownField
                            :optionsData="[
                                {name: 'Online', value: 'online'},
                                {name: 'Facebook', value: 'facebook'},
                                {name: 'Twitter', value: 'twitter'},
                                {name: 'Instagram', value: 'instagram'},
                                {name: 'Youtube', value: 'youtube'},
                            ]"
                            label="How Did You Hear About Us"
                            name="source"
                            :value="registrationFormHandler.values.source"
                            :errors="registrationFormHandler.errors.source"
                            @change="registrationFormHandler.handleChange"
                            class="mt-[16px]"
                        />
                    </div>

                    <div class="grid gap-[16px]" v-if="currentStep === 2">
                        <div class="grid grid-cols-2 gap-[12px]">
                            <InputField
                                type="text"
                                label="Number Of Stores"
                                name="numberOfStores"
                                :value="registrationFormHandler.values.numberOfStores"
                                :errors="registrationFormHandler.errors.numberOfStores"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />
                            <InputField
                                type="text"
                                label="Instagram Username"
                                name="instagram"
                                :value="registrationFormHandler.values.instagram"
                                :errors="registrationFormHandler.errors.instagram"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />
                        </div>

                        <InputField
                            type="text"
                            label="Current Solution In Use"
                            name="currentPOSSolution"
                            :value="registrationFormHandler.values.currentPOSSolution"
                            :errors="registrationFormHandler.errors.currentPOSSolution"
                            @update:blur:value="registrationFormHandler.handleBlur"
                            @update:value="registrationFormHandler.handleChange"
                        />
                            
                        <div class="text-body-2 text-grey-200 mt-[7px] inline-block">Address</div>
                        <div class="grid grid-cols-2 gap-[16px] bg-[#EDEDED] p-[20px] rounded-[8px]">
                            <InputField
                                type="text"
                                label="Street"
                                name="address.street"
                                :value="registrationFormHandler.values.street"
                                :errors="registrationFormHandler.errors.street"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />

                            <InputField
                                type="text"
                                label="Local Government Area"
                                name="address.lga"
                                :value="registrationFormHandler.values.lga"
                                :errors="registrationFormHandler.errors.lga"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />

                            <InputField
                                type="text"
                                label="City"
                                name="address.city"
                                :value="registrationFormHandler.values.city"
                                :errors="registrationFormHandler.errors.city"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />

                            <InputField
                                type="text"
                                label="State"
                                name="address.state"
                                :value="registrationFormHandler.values.state"
                                :errors="registrationFormHandler.errors.state"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />

                            <InputField
                                type="text"
                                label="Postal Code"
                                name="address.postalCode"
                                :value="registrationFormHandler.values.postalCode"
                                :errors="registrationFormHandler.errors.postalCode"
                                @update:blur:value="registrationFormHandler.handleBlur"
                                @update:value="registrationFormHandler.handleChange"
                            />
                        </div>

                        <TextArea
                            label="What Problem Would You Like Us To Solve?"
                            name="notes"
                            :value="registrationFormHandler.values.notes"
                            :errors="registrationFormHandler.errors.notes"
                            @update:blur:value="registrationFormHandler.handleBlur"
                            @update:value="registrationFormHandler.handleChange"
                            placeholder="Type here"
                        />

                        <div class="text-body-2 text-grey-200 mt-[7px] inline-block">Do you have an existing device?</div>
                        <div class="flex gap-[32px]">
                            <RadioButton value="true" :isChecked="registrationFormHandler?.values?.hasDevice === true" name="hasDevice" @on:change="registrationFormHandler.handleChange" />
                            <RadioButton value="false" :isChecked="registrationFormHandler?.values?.hasDevice === false" name="hasDevice" @on:change="registrationFormHandler.handleChange" />
                            <!-- <RadioButton value="Unavailable" name="avail" /> -->
                        </div>
                    </div>

                    <ActionButton
                        theme="primary"
                        :label="currentStep == 1 ? 'Next' : 'Submit'"
                        @click="currentStep == 1 ? nextStep() : null"
                        :is-submit="currentStep == 1 ? false : true"
                        class="my-[24px]"
                        :disable="currentStep == 1 ? !isStep1Complete() : !isStep2Complete()"
                    />
                </form>


                <div class="text-caption text-grey-200">
                    By clicking "Submit", you agree to 
                    <span class="underline text-grey-300 font-capitalize">FoodCart Terms and Conditions</span> 
                    and acknowledge you have read the <span class="underline text-grey-300 font-capitalize">
                    Privacy Notice</span>.
                </div>

                <div class="underline text-grey-300 font-capitalize border-t-[1px] border-grey-50 pt-[24px] mt-[24px] text-center">Already have an account?</div>
            </main> 
        </aside>

        <aside class="lg:block hidden p-[20px]">
            <div class="relative" style="
                width: 100%;
                height: 95.6vh;
                border-radius: 15px;
                background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Fpremium-photo%2Fhappy-woman-cashier-portrait-with-smile-management-small-business-restaurant-positive_590464-254776.jpg&f=1&nofb=1&ipt=1183d4782c574805c04ed8368374678a3d39078c45b3fea1e8bee67266590766&ipo=images');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            ">
                <div 
                    class="absolute inset-0 h-[70%] top-[auto]" 
                    style="
                        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                        border-radius: 14px;
                    "
                ></div>

                <div class="text-white w-[640px] absolute bottom-[80px] left-[40px]">
                    <div class="text-heading-3 my-[12px] font-bold">
                        FoodCart - Simplify Restaurant <br /> Operations
                    </div>

                    <div class="text-[#FFFFFFCC] font-medium">
                        "With FoodCart, managing sales, inventory, and transactions has never been easier. Our platform consolidates all your restaurant data into one place, allowing you to streamline your operations and focus on what matters most – serving your customers."
                    </div>
                </div>
            </div>
        </aside>
    </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { useRegistration } from '@/utility/composables/useAuthSession';
import AuthService from '@/utility/services/auth.service';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';

const router = useRouter();
const { registrationFormHandler } = useRegistration();
const currentStep = ref(1);
const registrationData = ref();

const requiredFieldsStep1 = [
    'name', 'serviceOptions', 'firstName', 'lastName', 'email', 'phone', 'source'
];

function isStep1Complete() {
    return requiredFieldsStep1.every(field => registrationFormHandler.values[field] && registrationFormHandler.values[field] !== '');
}

function isStep2Complete() {
    const addressFields = ['street', 'lga', 'city', 'state', 'postalCode'];
    
    const isAddressComplete = addressFields.every(field => 
        registrationFormHandler.values.address && 
        registrationFormHandler.values.address[field] && 
        registrationFormHandler.values.address[field] !== ''
    );

    const isOtherFieldsComplete = registrationFormHandler.values.numberOfStores &&
                                  registrationFormHandler.values.instagram &&
                                  registrationFormHandler.values.currentPOSSolution &&
                                  registrationFormHandler.values.notes &&
                                  registrationFormHandler.values.hasDevice;

    return isAddressComplete && isOtherFieldsComplete;
}

const handleRegistrationSubmit = async (event: Event) => {
    event.preventDefault();
    
    // if (currentStep.value === 2 && !isStep2Complete()) {
    //     return; 
    // }

    await registrationFormHandler.handleSubmit(event);
    registrationData.value = registrationFormHandler.values;

    router.push('/success');
};

function nextStep() {
    if (currentStep.value === 1 && isStep1Complete()) {
        currentStep.value++;
    }
}

watchEffect(() => {
    console.log(registrationData.value);
});
</script>

<style lang="scss" scoped>

</style>