import ApiService from './api.service';

export default class AuthService {
    
    static async registrationRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('1.0/p/account/signup', { ...values })
        return response.data
    }

    static async contactUsRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('1.0/p/account/contact-us', { ...values })
        return response.data
    }

    static async categoriesRequest(): Promise<unknown> {
        const response = await ApiService.get('1.0/restaurant/setup/category')
        return response.data.data
    }
}