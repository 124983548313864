import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[6px] absolute p-[20px] bg-gray-100" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center h-screen bg-gray-100 text-center" }

import ImageCard from '@/components/shared/image/ImageCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUsSuccessView',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/logos/foodcart.png'),
            width: "48",
            height: "48"
          }, null, 8, ["imageUrl"]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-3xl font-bold text-green-600 mb-4" }, "You've reached out!", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-lg text-gray-700 mb-6" }, "Thank you for reacting out to FoodCart. We will get back to you shortly.", -1)),
      _createVNode(_component_router_link, {
        to: "/",
        class: "px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500 transition duration-300 bg-primary-300"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Return back")
        ])),
        _: 1
      })
    ])
  ], 64))
}
}

})