import { computed } from 'vue';
import AuthService from '../services/auth.service';
import { useForm } from './useForm';

export const useRegistration = () => {

  const registrationFormHandler = useForm({
    initialValues: {
      name: '',
      serviceOptions: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      source: '',
      numberOfStores: '',
      instagram: '',
      currentPOSSolution: '',
      notes: '',
      hasDevice: '',
    },
    onSubmit: async (values: any) => {
      console.log(values)
      try {
        await AuthService.registrationRequest(values);
      } catch (error) {
        console.error('Registration failed', error);
      }
    }
  });

  const allFieldsFilled = computed(() => {
    return Object.keys(registrationFormHandler.values).every((key) => registrationFormHandler.values[key] && !registrationFormHandler.errors[key]);
  });

  return {
    registrationFormHandler,
    allFieldsFilled
  };
};

export const useContactUs = () => {
  
  const contactUsFormHandler = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      notes: '',
    },
    onSubmit: async (values: any) => {
      try {
        await AuthService.contactUsRequest(values);
      } catch (error) {
        console.error('Registration failed', error);
      }
    }
  });

  const allFieldsFilled = computed(() => {
    return Object.keys(contactUsFormHandler.values).every((key) => contactUsFormHandler.values[key] && !contactUsFormHandler.errors[key]);
  });

  return {
    contactUsFormHandler,
    allFieldsFilled
  };
};
